.SkillWardrobre--wrapper .details-container {
    width: fit-content ;
    padding: 1.5rem;
    flex: 1 1;
    border-radius: 2rem;
    font-family: 'Comfortaa-Regular';
    border: var(--primary) 0.1rem solid;
    border-color: var(--primary);
    color: var(--primary);
    text-align: center;
}
article {
    display: flex;
    width: 10rem;
    justify-content: space-around;
    gap: 0.5rem;
}
.article-container {
    display: flex;
    text-align: initial;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5rem;
    justify-content: space-around;
}
.skill--name{
    margin-top: 21px !important;
}
.skill--level{
    margin-top: -10px !important; 
}
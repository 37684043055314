/* Navbar.css */
.navbar {
  color: var(--text);
  padding: 20px 0;
  font-family: 'VT323-Regular'; 
  width: 80%;
  margin-top: 1vh;
}
@font-face {
    font-family: 'VT323-Regular';
    src: url('../../fonts/VT323-Regular.ttf') format('truetype');
  }

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.navbar-logo a {
  text-decoration: none;
  font-size: 32px;
  color: var(--text);
  
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin: 0 20px;
}

.navbar-menu a {
  text-decoration: none;
  color: var(--text);
  font-size: 24px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  background-color: var(--primary);
  height: 3px;
  width: 25px;
  margin: 3px 0;
  transition: 0.4s;
}

.active .bar:first-child {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(2) {
  opacity: 0;
}

.active .bar:last-child {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 999px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: var(--background);
    text-align: center;
    padding: 5px 0;
  }

  .navbar-menu.active {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
  }
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--primary); }
  }
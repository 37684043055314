@font-face {
  font-family: 'VT323-Regular';
  src: url('./fonts/VT323-Regular.ttf') format('truetype');
  /* You might need to adjust the path to your font file */
}
@font-face {
  font-family: 'Comfortaa-Regular';
  src: url('./fonts/Comfortaa-Regular.ttf') format('truetype');
  /* You might need to adjust the path to your font file */
}
:root {
  text-align: center;
  --text: #f9f9fa;
  --background: #141619;
  --primary: #bec5cb;
  --secondary: #2d3339;
  --accent: #8e99a4;  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.Section--About--wrapper{
  justify-content: center;
  flex-wrap: wrap;
  gap: 7rem;
  display: flex;
  max-width: 1256px;
  flex-direction: row;

}

.Section--About--photo{
  display: flex;
  height: 400px;
  width: 400px;
  margin: auto 0;
}
.Section--About--photo img{
  border-radius: 2rem
}
@media screen and (max-width: 1500px){
  .Section--About--Skillls--wrapper{
    padding: 30px !important;
  }
  
}
@media screen and (max-width: 600px){
.Section--About--photo {
    width: auto;
    height: 46vw;
    justify-content: center;
}

.details-container {
  width: fit-content;
  padding: 1.5rem;
  flex: 1 1;
  border-radius: 2rem;
  font-family: 'Comfortaa-Regular';
  border: var(--primary) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  color: var(--primary);
  text-align: center;
}
.Section--About--wrapper {
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    display: flex;
    max-width: 1256px;
    flex-direction: row;
}
.Section--About--resume p{
  padding: 15px;
}


}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Section--Title{
  margin-top: 8rem;
  font-family: 'Comfortaa-Regular';
  color: var(--text) !important;
  font-size:  3.115264797507788vh;
  animation: fadeIn 5s;
}
.Section--subtitle{
  font-family: 'Comfortaa-Regular';
  margin-top: -10px;
  font-weight: 33;
  color: var(--text) !important;
  font-size:  1.7vh;
  animation: fadeIn 5s;
  margin-bottom: 3rem;
}
.App-header {
  background-color: var(--background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.Section--About--Skills{
  gap: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   
}
.Section--About{
  margin-bottom: 1rem;
  animation: fadeIn 5s;
}
.Section--About--Skillls--wrapper{
  display: flex;
  gap: 1rem;
  max-width: 744px;
  flex-direction: column;
}

.Section--About--resume{
  font-size: 16px;
  color: var(--text);
  overflow: hidden;
  word-wrap: break-word;
  max-width: 100%;
  width: 100%;
  font-family: 'Comfortaa-Regular';
  text-align: justify;
}
.Section--Experience{
  animation: fadeIn 5s;
}
.Section--Projects{
  animation: fadeIn 5s;
}
.Section--Contact{
  animation: fadeIn 5s;
}
.Section--Experience--wrapper{
  max-width: 1256px;
  padding: 30px;
}
.Section--Contact--wrapper{
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: var(--primary) 0.1rem solid;
  border-color: var(--primary);
  background: (250, 250, 250);
  margin: 2rem auto;
  padding: 0.5rem
}
.Section--Contact--item{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.Section--Contact--item p{
  font-size: 16px;
  color: var(--text);
  white-space: pre-line;
  font-family: 'Comfortaa-Regular';
}
.Section--Contact--wrapper{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
}
footer p{
  font-size: 16px;
  color: var(--text);
  white-space: pre-line;
  font-family: 'Comfortaa-Regular';
}
.Project--Container {
  display: flex;
    justify-content: center;
    border-radius: 2rem;
    border: var(--primary) 0.1rem solid;
    border-color: var(--primary);
    background: (250, 250, 250);
    margin: 2rem auto;
    padding: 0.5rem;
    flex-direction: column;
}
.Project--Container img {

  align-self: center;
  width: 14rem;
  height: 14rem;
  border-radius: 2rem;
}
.Project--Container h3 {

  font-size: 20px;
  color: var(--text);
  white-space: pre-line;
  font-family: 'Comfortaa-Regular';
}

.Project--Container button{
  background-color: var(--background);
	border-radius:28px;
	border:1px solid var(--primary);
	display:inline-block;
	cursor:pointer;
	color:var(--text);
	font-family:"Comfortaa-Regular";
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px var(--accent);
}

.Project--Container button:hover{
  background-color: var(--text);
  color: var(--secondary);
}

.Project--Container{
  padding: 3rem;
}
.Section--Projects--wrapper{
  max-width: 1256px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5rem;
}
.details-container {

    padding: 1.5rem;
    flex: 1 1;
    border-radius: 2rem;
    font-family: 'Comfortaa-Regular';
    border: var(--primary) 0.1rem solid;
    border-color: var(--primary);
    color: var(--primary);
    text-align: center;

  }
  .details-container h3 {
    font-size: 18px;
  }
  .details-container p {
    font-size: 16px;
    color: var(--text);
    white-space: pre-line;
  }
  .icon{
    cursor: pointer;
    height: 2rem;
    align-self: center;
}
.status-light {
  margin-bottom: 3px;
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 20px;
  background-color: var(--accent);
  box-shadow: 0 0 5px var(--accent);
  margin-right: 7px;
}